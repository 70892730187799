<template>
  <div class="wrap">
    <div class="top">
      <div class="searinno">
        {{index}}
      </div>
      <!-- <div class="score">
        <span style="color:#cccccc;margin-right:50rem;display:inline-block;">
          {{topic.question_score}}
        </span>
        <el-input v-model="score"
                  @input="change"
                  type="number"
                  :max="Number(max_score)"
                  style="width: 223rem;height:102rem;font-size:30rem;" />
        <span class="fen">分</span>
      </div> -->
    </div>
    <div class="bottom">
      <el-input type="textarea"
                placeholder="请输入"
                @input="change"
                :rows="3"
                v-model="comment"
                style="font-size:30rem"/>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      comment: '',
      score: ''
    }
  },
  props: {
    max_score: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    },
    topic: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  mounted () {
    this.comment = this.topic.comment
    this.score = this.topic.score
    this.change()
  },
  methods: {
    change () {
      if (this.score > this.max_score) {
        this.score = this.max_score
      }
      this.$emit('setComment', this.index, this.comment, this.score)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  box-shadow: 0rem 3rem 20rem 3rem rgba(187, 187, 187, 0.18);
  border-radius: 20rem;
  margin-top: 46rem;
  .top {
    display: flex;
    justify-content: space-between;
    height: 220rem;
    // width: calc(100% - 144rem);
    // margin-left: 72rem;
    border-bottom: 3rem dashed #e3e3e3;
    .searinno {
      // background-image: url("../../../assets/block.png");
      background-size: 100% 100%;
      // background-repeat: no-repeat;
      width: 50rem;
      height: 60rem;
      background: #2196f3;
      border-radius: 0rem 0rem 79rem 79rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 30rem;
      margin-left: 20rem;
    }
    .score {
      width: 410rem;
      display: flex;
      align-items: center;
      margin-top: 0rem;
      font-weight: 400;
      color: #ffffff;
      font-size: 30rem;
      ::v-deep .el-input--medium .el-input__inner {
        height: 102rem !important;
        line-height: 102rem !important;
        border-right: none;
        padding: 0;
        text-align: center;
      }
      .fen {
        background: #2196f3;
        height: 102rem;
        line-height: 102rem;
        font-size: 30rem;
        padding-left: 10rem;
        padding-right: 10rem;
        margin-right: 30rem;
        border-radius: 0rem 5rem 5rem 0rem;
        position:relative;
        left:-3rem;
      }
    }
  }
  .bottom {
    ::v-deep .el-input__inner {
      border: none;
      margin: 54rem 0 51rem 31rem;
    }
  }
}
</style>